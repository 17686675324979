
import {defineComponent, reactive, ref, watch} from "vue";
import UserService from "@/core/services/UserService";

export default defineComponent({
  name: "UserListSelect",
  props: {
    clazz: {type: String, default: () => 'form-control form-control-solid form-control-lg fw-bold'},
    modelValue: {},
    showCompany: {type: Boolean, default: () => true},
    showInactive: {tye: Boolean, default: () => false},
    allowEmpty: {type: Boolean, default: () => true},
    teams: {type: Array, default: () => []},
    multiple: {type: Boolean, default: () => false},
    filterByClient: {type: Boolean, default: false},
    clientId: {type: String, default: ''},
  },
  setup(props) {

    const options = ref<any>([])
    const loading = ref(false);
    const state = reactive<any>({
      show: false,
      selectObjects: []
    })
    const loadOptions = (teams: any[]) => {
      loading.value = true
      const teamCodes = teams.join(",");
      UserService.userLists(teamCodes).then(res => {
        options.value = res;
        loadSelectedItem();
      }).finally(() => {
        loading.value = false;
      })
    }
    loadOptions(props.teams)
    watch(() => props.modelValue, (cb: any) => {
      if (cb != undefined && cb.length === 0) {
        state.selectObjects = []
      } else {
        if (!props.multiple) {
          const selected = options.value.find(item => item.users.find(k => k['id'] === cb));
          if (selected) {
            const user = selected.users.find(item => item['id'] === cb)
            state.selectObjects = [user];
          }
        } else {
          cb.forEach(i => {
            const selected = options.value.find(item => item.users.find(k => k['id'] === i));
            if (selected) {
              const user = selected.users.find(item => item['id'] === i)
              if (!state.selectObjects.includes(user)) {
                state.selectObjects.push(user);
              }
            }
          })
        }
      }
    })
    const loadSelectedItem = () => {
      const modelValue = ref<any>(props.modelValue);
      if (modelValue.value && modelValue.value.length === 0) {
        state.selectObjects = []
      } else {
        if (!props.multiple) {
          const selected = options.value.find(item => item.users.find(k => k['id'] === modelValue.value));
          if (selected) {
            const user = selected.users.find(item => item['id'] === modelValue.value)
            state.selectObjects = [user];
          }
        } else {
          modelValue.value.forEach(i => {
            const selected = options.value.find(item => item.users.find(k => k['id'] === i));
            if (selected) {
              const user = selected.users.find(item => item['id'] === i)
              if (!state.selectObjects.includes(user)) {
                state.selectObjects.push(user);
              }
            }
          })
        }
      }
    }
    return {
      state,
      loading,
      options,
    }
  },
  emits: ['update:modelValue', 'onSelect'],
  methods: {
    onClose() {
      this.state.show = false
    },
    remove(item) {
      this.state.selectObjects = this.state.selectObjects.filter(val => val !== item)
    },
    clear() {
      this.$emit('update:modelValue', "")
      this.state.selectObjects = [];
    },
    onSelect(item) {
      if (this.state.selectObjects.includes(item)) {
        return
      } else {
        if (this.multiple) {
          this.state.selectObjects.push(item)
        } else {
          this.state.selectObjects = [item]
        }
      }
      const ids = this.state.selectObjects.map(value => value.id)
      this.$emit('update:modelValue', this.multiple ? ids : ids[0])
      this.$emit('onSelect', this.multiple ? ids : ids[0])
    }
  }
})
